<template>
  <div id="app">
    <div class="layout_login" v-if="hrefUrl != 'hnkdtdxlkspx.ykumda.com' && hrefUrl != 'hnkdtdxlkcxx.ykumda.com'">
      <div class="app_header">
        <div class="header w">
          <div class="nav_logo">
            <NuxtLink to="/" v-if="route.path !== '/settlement'">
              <img :src="nav && nav.websiteLogo" alt="">
            </NuxtLink>
          </div>
          <div class="handle_bar" @click="goBack">
            <span class="handle_bar_item">返回首页 <i class="iconfont icon-icon_Go" /></span>
          </div>
        </div>
      </div>
      <div class="app_main w">
        <slot />
      </div>
      <div class="footer w">
        <div class="copyright">
          <p>Copyright © {{ componentInfo && componentInfo.copyright }}</p>
          <!-- <p>粤ICP备16009964号<span>|</span><img src="/assets/images/login/copyright.png" alt="" />粤公网安备44010602005928号</p> -->
        </div>
      </div>
      <back-top />
    </div>

    <!-- spx 首页样式 -->
    <div class="layout_loginHnkjspx" v-else-if="hrefUrl == 'hnkdtdxlkspx.ykumda.com'">
      <div class="login_center">
        <div class="roncoon_logo">
          <!-- <NuxtLink to="/" v-if="route.path !== '/settlement'">
            <img :src="nav && nav.websiteLogo" alt="">
          </NuxtLink> -->
        </div>
        <!-- <div style="position: absolute;right: 40px;top: 40px;" @click="goBack">
          <span class="handle_bar_item1">返回首页 ><i class="iconfont icon-icon_Go" /></span>
        </div> -->
        <slot />
      </div>
    </div>

    <!-- cxx 首页样式 -->
    <div class="layout_loginHnkjcxx" v-else-if="hrefUrl == 'hnkdtdxlkcxx.ykumda.com'">
      <div class="login_center">
        <div class="roncoon_logo">
          <!-- <NuxtLink to="/" v-if="route.path !== '/settlement'">
            <img :src="nav && nav.websiteLogo" alt="">
          </NuxtLink> -->
        </div>
        <!-- <div style="position: absolute;right: 40px;top: 40px;" @click="goBack">
          <span class="handle_bar_item1">返回首页 ><i class="iconfont icon-icon_Go" /></span>
        </div> -->
        <slot />
      </div>
    </div>
  </div>

</template>

<script setup>
import cookie from '@/utils/cookies'
import { useUserStore } from '../stores/user'
import { getHearder, getFooter } from '@/api/layouts'
const { data: nav } = await useAsyncData('loginHeader', () => {
  return getHearder()
}, {})
const { data: componentInfo } = await useAsyncData('loginFooter', () => {
  return getFooter()
}, {})
const hrefUrl = ref(null)
const { getShopInfo } = useUserStore()
getShopInfo()

const router = useRouter()
const goBack = () => {
  router.push({ path: '/' })
}
const route = useRoute()
const cookieData = cookie.getShop()
onMounted(() => {
  hrefUrl.value = window.location.hostname;
})
useHead({
  link: [{ rel: 'icon', type: 'image/x-icon', href: cookieData.shopIcon }]
})
</script>

<style lang="scss" scoped>
.nav_logo {
  height: 36px;
  width: 170px;

  img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }
}
// ../assets/images/login/bj.jpg
.layout_login {
  min-width: 1200px;
  min-height: 100vh;
  background: rgba(36, 91, 246, 1) url("../assets/images/login/bjHnkjspxs.jpg") no-repeat;
  background-size: cover;
}

.app_header {
  background: #fff;
}

.header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .handle_bar_item {
    cursor: pointer;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  padding-bottom: 30px;
  font-size: 12px;

  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    p {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 14px;
        height: 14px;
        margin: 0 5px;
      }

      span,
      img {
        margin-left: 5px;
      }
    }
  }
}

.layout_loginHnkjspx {
  min-width: 1200px;
  min-height: 100vh;
  background: rgba(36, 91, 246, 1) url("../assets/images/login/bjHnkjspxs.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;

  .login_center {
    position: relative;
    width: 80vw;
    height: 90vh;
    // border: 8px solid #bfcef9;
    // background: #f4f9ff;
    border-radius: 40px;
    margin: 40px auto;
    display: flex;
    overflow: hidden;

    .box_left {
      flex: 2;
      height: 100%;
    }

    .roncoon_logo {
      position: absolute;
      width: 266px;
      height: 56px;
      object-fit: cover;
      margin: 40px 0 0 40px;
    }

    .roncoon_hnkj {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }

    .box_right {
      flex: 1;
      min-width: 680px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .handle_bar_item1 {
      cursor: pointer;
      color: #537def;
    }
  }
}


.layout_loginHnkjcxx{
  min-width: 1200px;
  min-height: 100vh;
  background: rgba(36, 91, 246, 1) url("../assets/images/login/bjHnkjcxx.png") no-repeat;
  background-size: cover;
  overflow: hidden;

  .login_center {
    position: relative;
    width: 80vw;
    height: 90vh;
    // border: 8px solid #bfcef9;
    // background: #f4f9ff;
    border-radius: 40px;
    margin: 40px auto;
    display: flex;
    overflow: hidden;

    .box_left {
      flex: 2;
      height: 100%;
    }

    .roncoon_logo {
      position: absolute;
      width: 266px;
      height: 56px;
      object-fit: cover;
      margin: 40px 0 0 40px;
    }

    .roncoon_hnkj {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }

    .box_right {
      flex: 1;
      min-width: 680px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .handle_bar_item1 {
      cursor: pointer;
      color: #537def;
    }
  }
}
</style>
